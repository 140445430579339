$BACKGROUND-COLOR: rgba(252, 252, 253, 1);


.LinkCreationDrawerArea{

    .LinkCreationInner{
        height: 100%;

        .LinkCreationWithLabels{
            width: 100%;
            background-color: rgb(255,255,255);
            border-radius: 4px;
        }
        .LinkCreationHeader{
            padding: 20px 24px;
            box-sizing: border-box;
            
            display: flex;
            justify-content: space-between;
            align-items: center;
            
            border-bottom: 1px solid rgba(242, 243, 247);

            .Title{
                font-family: Pretendard;
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 600;
                line-height: 24px; /* 133.333% */
            }
        }
        .LinkCreationContents{
            padding: 32px 24px;
            box-sizing: border-box;

            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 32px;
            
            
            
            .AddressGenerator{
                width: 100%;
    
                .GeneratorInputBox{
                    width: 100%;
                    
                    display: flex;
                    gap: 16px;
            
                    font-family: Pretendard;
                    font-style: normal;
            
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 28px; /* 160% */
                    letter-spacing: 0.16px;
                
                    flex-direction: row;
                    align-items: flex-end;
            
                    .FixedAddress{
                        display: flex;
                        align-items: center;
                        align-content: center;
                        gap: 8px;
            
                        color: rgba(189, 189, 194, 1);

                        .Address{
                            box-sizing: border-box;
            
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 10px;
            
                            border-radius: 5px;
                            font-size: 0.9375rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 24px; /* 160% */
                            letter-spacing: 0.15px;
                        }
                    }
                }
    
                .RandomUrlCheckBox{
                    display: flex;
                    justify-content: flex-end;
    
                    .RandomCustomUrlCheck{
                        padding: 0;
                        border: 0;
    
                        display: flex;
                        flex-direction: row-reverse;
                        align-items: center;
                        gap: 8px;
    
                        background-color: rgba(255,255,255,1);
                        border-radius: none;
                    }
                }
            }
        }
    }

    .LinkCreationFooter{
        width: 100%;
        height: 70px;
        padding: 16px 24px;
        box-sizing: border-box;

        display: flex;
        justify-content: flex-end;
        gap: 8px;
        position: sticky;
        bottom: 0;
        
        background-color: $BACKGROUND-COLOR;
        border-top: 1px solid rgba(242, 243, 247);

        .ButtonBox{
            display: flex;
            gap: 16px;

            text-align: right;
            font-family: Pretendard;
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 150% */
            letter-spacing: 0.16px;

            .CancelButton{
                display: flex;
                padding: 8px 16px;
                justify-content: center;
                align-items: center;
                gap: 7px;
                border-radius: 8px;
                border: 1px solid rgba(237, 236, 238, 1);
                color: rgba(60, 70, 81, 1);
            }
            .CreateUrlButton{
                display: flex;
                padding: 8px 16px;
                justify-content: center;
                align-items: center;
                gap: 7px;
                border-radius: 8px;
                background-color: rgba(61, 106, 255, 1);
                color: rgba(255, 255, 255, 1);
                
                &.Disabled{
                    background: linear-gradient(0deg, rgba(220, 220, 220, 0.32) 0%, rgba(220, 220, 220, 0.32) 100%), #FFF
                }
            }
        }
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
        border-color: rgba(61, 106, 255, 1);
    }
}