.PersonalSetting{
    min-height: calc(100vh - 72px);
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(245, 246, 247, 1);

    .PersonalSettingContent0{
        box-sizing: border-box;
        padding: 32px 16px;
        width: 480px;
        border: 1px solid rgba(237, 236, 238, 1);
        border-radius: 5px;

        display: flex;
        flex-direction: column;
        gap: 40px;

        background-color: rgba(255, 255, 255, 1);

        > div{
            padding: 0px 14px;

            display: flex;
            flex-direction: column;
            gap: 20px;

            .Input{
                box-sizing: border-box;
                border-radius: 5px;
                border: 1px solid rgba(236, 237, 243, 1);
                padding: 2px 16px 2px 14px;
                height: 48px;

                display: flex;
                align-items: center;

                input{
                    width: 100%;
        
                    border: none;
                    background: none;
        
                    &::placeholder{
                        color: rgba(189, 189, 194, 1);
                    }
        
                    &:focus{
                        outline: none;
                    }

                    &[type="date"].Empty{
                        color: rgba(189, 189, 194, 1);
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

    .PersonalSettingContent1{
        box-sizing: border-box;
        padding: 32px 16px;
        width: 480px;
        border: 1px solid rgba(237, 236, 238, 1);
        border-radius: 5px;

        display: flex;
        flex-direction: column;
        gap: 104px;

        background-color: rgba(255, 255, 255, 1);

        > div{
            padding: 0px 14px;

            display: flex;
            flex-direction: column;
            gap: 40px;
        }

        .Header{
            color: rgba(25, 24, 27, 1);
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 600;
            line-height: 36px; /* 150% */
            letter-spacing: -0.24px;
        }

        .Content{
            display: flex;
            flex-direction: column;
            gap: 4px;

            .SelectAllItems{
                padding: 12px 0px;

                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 16px;

                color: rgba(25, 24, 27, 1);
                font-size: 1rem;
                font-style: normal;
                font-weight: 600;
                line-height: 24px; /* 150% */
                letter-spacing: 0.16px;

                .CustomCheckBox{
                    width: 20px;
                    height: 20px;
                    flex-shrink: 0;
                    position: relative;
            
                    cursor: pointer;
            
                    .CheckMark{
                        display: inline-block;
                        width: 100%;
                        height: 100%;
            
                        border-radius: 4px;
                        border: 1px solid rgba(121, 120, 130, 1);
                        box-shadow: 0px 0px 2px 0px rgba(121, 120, 130, 1) inset;
            
                        background-color: rgba(246, 246, 249, 1);
                    }
            
                    input:checked + .CheckMark{
                        background: rgba(61, 106, 255, 1) url('https://static.webtoon.today/ddah/icon/icon_checkmark.svg') center/1rem no-repeat;
                    }
            
                    input {
                        display: none;
                    }
                }
            }

            .Item{
                padding: 6px 0px;

                display: flex;
                flex-direction: row;
                justify-content: space-between;

                > div{
                    display: flex;
                    flex-direction: row;
                    gap: 16px;

                    color: rgba(121, 120, 130, 1);
                    font-size: 0.8125rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px; /* 153.846% */
                    letter-spacing: 0.13px;

                    img{
                        cursor: pointer;
                        filter: brightness(0) saturate(100%) invert(86%) sepia(0%) saturate(0%) hue-rotate(22deg) brightness(108%) contrast(79%);

                        &.Selected{
                            filter: brightness(0) saturate(100%) invert(45%) sepia(94%) saturate(3694%) hue-rotate(215deg) brightness(96%) contrast(116%);
                        }
                    }
                }

                > span{
                    color: rgba(121, 120, 130, 1);
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 142.857% */
                    letter-spacing: 0.14px;
                    text-decoration-line: underline;

                    cursor: pointer;
                }
            }
        }
    }
}