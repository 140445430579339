.WithLabelContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .Label {
        display: flex;
        padding-left: 4px;
        align-items: center;
        gap: 4px;
        align-self: stretch;

        color: rgba(60, 70, 81, 1);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */

        img {
            cursor: pointer;
        }
    }
    .Content{
        display: flex;
        flex-direction: column;
    }
}