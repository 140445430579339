
.BasicDialog{
    z-index: 1;
    .BasicDialogPaper{
        padding: 24px;
        border-radius: 16px;
        box-shadow: 0px 0px 0px 1px rgba(12, 22, 39, 0.05), 0px 14px 50px 15px rgba(12, 22, 39, 0.12);
        .BasicDialogTitle{
            padding: 12px 36px;
            
            .TitleText{
                font-size: 1.125rem;
                font-weight: 600;
            }
        }
        .BasicDialogContent{
            white-space: pre-line;
            color: rgba(121, 120, 130, 1);
            font-size: 0.875rem;
            font-weight: 500;
            text-align: center;
            padding: 0 0 24px 0;
        }
        .BasicDialogActions{
            display: flex;
            flex-direction: column;
            padding: 0;
            gap: 8px;

            
            .BasicDialogButton{
                font-family: inherit;
                height: 44px;
                font-weight: 600;
            }
            .ExitButton{
                background-color: rgba(235, 81, 71, 1);
                color: rgb(255, 255, 255);
                border-radius: 8px;
            }
            .CancelButton{
                margin: 0;
            }
        }
    }
}