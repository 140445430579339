$HEADER_HEIGHT: 72px;

.ForbiddenPage {
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color:  rgb(244, 245, 246);

    header {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0; left: 0;

        width: 100%;
        height: $HEADER_HEIGHT;

        box-sizing: border-box;
        padding: 0 40px;
    }

    .Content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        gap: 20px;

        padding: 0 20px;

        height: 100vh;

        .TextBox {
            display: flex;
            flex-direction: column;
            gap: 4px;

            text-align: center;
            font-style: normal;

            word-wrap: break-word;
            word-break: keep-all;

            .Title {
                font-weight: 600;
                color: rgb(25, 24, 27);
                
                font-size: 1.5rem;
                line-height: 36px; /* 150% */
                
                @media (min-width: 480px) {
                    font-size: 2rem;
                    line-height: 48px; /* 150% */
                }

                @media (min-width: 1024px) {
                    .ResponsiveLineBreak {
                        display: none;
                    }
                }
            }
    
            .SubTitle {
                font-size: 1rem;
                font-weight: 400;
                line-height: 24px; /* 150% */
                
                color: rgb(60, 70, 81);
            }
        }

        .HomeButton {
            display: flex;
            align-items: center;
            justify-content: center;

            padding: 8px 20px;

            border-radius: 60px;
            background-color: rgb(51, 51, 53);

            text-align: center;
            font-style: normal;
            font-family: Pretendard;

            font-size: 1rem;
            font-weight: 600;
            line-height: 24px; /* 150% */
            letter-spacing: 0.16px;
            
            color: rgb(255, 255, 255);
        }
    }
}