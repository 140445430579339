
.MarketingMetricChartsArea{
    .MarketingMetricChartsInner{
        display: flex;
        flex-direction: column;

        .MarketingMetricRow{
            display: flex;
            flex-direction: column;
            margin-top: 40px;
            container-type: inline-size;

            .MarketingPurpose{
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                width: 100%;
                margin: 0 auto;
                margin-bottom: 24px;

                width: calc(100% - 24px);

                .Title{
                    font-size: 1.5rem;
                    font-weight: bold;             
                }

                .EstimatedLineSwitchBox{  
                    height: 100%;

                    .MuiFormControlLabel-label {
                        font-size: 0.75rem;
                    }
                    
                    .Root{
                        width: 46px;
                        height: 22px;
                        padding: 0;
                        margin: 8px;

                        .SwitchBase{
                            padding: 1px;
                            height: calc(100% - 2px);
                            vertical-align: center;
                            left: 2px;

                            .FocusVisible .Thumb{
                                color: #13BF11;
                                border: 6px solid #ffffff;
                            }

                            .Thumb{
                                width: 16px;
                                height: 16px;
                                vertical-align: middle;
                            }
                        }
                        .SwitchBase.Checked{
                            color: white;
                            transform: translateX(24px);
                            .Thumb{
                                color: #ffffff;
                            }
                        }

                        .Track{
                            border: 1px solid #bdbdbd;
                            box-sizing: border-box;
                            opacity: 1;
                            transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                            border-radius: 40px;
                            background-color: rgba(180, 180, 180, 1);

                            &::after{
                                content: 'OFF';
                                color: rgb(255,255,255);
                                font-weight: bold;
                                font-size: 0.625rem;
                                font-family: inherit;
                                display: flex;
                                justify-content: flex-end;
                                align-items: center;
                                height: 100%;
                                padding-right: 5px;
                            }
                        }
                        .CheckedTrack{
                            background-color: #13BF11;
                            border: none;

                            &::before{
                                content: 'ON';
                                color: white;
                                font-weight: bold;
                                font-size: 0.625rem;
                                font-family: inherit;
                                vertical-align: middle;
                                padding-left: 5px;
                            }
                        }

                    }
                }
            }

            .MarketingChartContainer{
                width: 100%;
                display: grid;
                grid-template-columns: repeat(auto-fill, 384px);

                @container (width < 792px) {
                    grid-template-columns: repeat(auto-fill, 300px);
                }

                justify-content: center;
                gap: 24px;

                .ChartOutlineArea{
                    width: 384px;
                    @container (width < 792px) { width: 300px }

                    height: 271px;
                    box-sizing: border-box;
                    padding: 24px;
                    border-radius: 10px;
                    background-color: rgb(255, 255, 255);
                    
                    .ChartPurpose{
                        display: flex;
                        align-items: flex-start;
                        height: 15%;

                        .TitleBox{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            font-weight: bold;
                            align-items: center;
                            margin-bottom: 24px;
                            width: 100%;
                            padding: 0 15px 0 10px;

                            .Title{
                                font-size: 1.125rem;
                                color: rgba(62, 70, 80, 1);
                            };

                            .Detail{
                                font-size: 0.625rem;
                                cursor: pointer;
                                color: rgba(119, 119, 119, 1);
                            };
                        }
                    }

                    .ChartSpace{
                        height: 85%;
                    }
                }

                .ChartOutlineArea:nth-child(3){
                    margin-right: 0;
                }
            }
        }
    }
}