
.WebtoonTodayNormalPopper{
    border-radius: 8px;
    border: 1px solid rgb(236, 237, 243);
    background: rgb(255,255,255);
    box-shadow: 0px 14px 24px 0px rgba(12, 16, 39, 0.12);
    margin-top: 8px;
    box-sizing: border-box;
    z-index: 1;

    .MuiAutocomplete-paper{
        margin: 0;
        box-shadow: none;
        border-radius: 8px;

        .MuiAutocomplete-listbox{
            padding: 12px 16px;
                
            &::-webkit-scrollbar{
                width: 12px;
                border-radius: 50px;
            }
        
            &::-webkit-scrollbar-track{
                background-color: rgba(217,217,217,0.8);
            }
        
            &::-webkit-scrollbar-thumb{
                width: 8px;
                background-color: rgb(150, 150, 150);
                border-radius: 50px;
            }
            li{
                padding: 8px 12px;
                
                color: rgba(60, 70, 81, 1);
                font-family: Pretendard;
                font-size: 0.9375rem;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 160% */
                letter-spacing: 0.15px;
            }
        }
    }
}