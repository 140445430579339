
body[data-scroll]:not([data-scroll="0"]) .DashboardHeader {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.WebtoonDashboardArea{
    box-sizing: border-box;
    width: 100%;
    container-type: inline-size;

    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: rgb(245, 246, 248);
    color: rgba(62, 70, 80, 1);
    padding: 20px;

    .DashboardHeader{
        position: sticky;
        top: 85px;
        background-color: rgb(245, 246, 248);
        
        @container (width <   792px)                      { width:  315px; padding: 0 calc(100% / 2 -  315px / 2); }
        @container (width >=  792px) and (width < 1200px) { width:  792px; padding: 0 calc(100% / 2 -  792px / 2); }
        @container (width >= 1200px) and (width < 1608px) { width: 1200px; padding: 0 calc(100% / 2 - 1200px / 2); }
        @container (width >= 1608px)                      { width: 1608px; padding: 0 calc(100% / 2 - 1608px / 2); }

        transition: 200ms;

        z-index: 2;
        white-space: nowrap;

        .HeaderInner{
            margin: 20px auto 20px auto;
            width: 100%;
            
            .HeadlineTitle{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 4px;
    
                color: rgba(158, 157, 164, 1);
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0.14px;
    
                :last-child{
                    color: rgba(60, 70, 81, 1);
                }
            }

            .Period {
                display: flex;
                justify-content: space-between;

                .PeriodInputContainer{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 12px;

                    flex-wrap: wrap;
                    white-space: initial;

                    span{
                        color: rgba(61, 106, 255, 1);
                        font-size: 0.8125rem;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 0.13px;

                        @container (width < 792px){
                            &::after{
                                content: " (데스크탑에서 지원)";
                            }
                        }
                    }
                }
                
                .HeaderButtonBox{
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    @container (width < 792px) {
                        display: none;
                    }

                    .AccountFormButton{
                        color: rgb(255,255,255);
                        padding: 8px 10px;
                        border-radius: 5px;
                        background-color: rgb(201, 201, 201);
                        text-align: right;
                        font-family: inherit;
                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        cursor: pointer;
                        pointer-events: none;

                        &.Enabled{
                            background-color: rgba(0, 176, 240, 1);
                            pointer-events: initial;
                            position: relative;
    
                            &::after{
                                content: "";
                                position: absolute;
                                background-image: url("https://static.webtoon.today/ddah/speech_bubble_temp_dashboard.png");
                                background-size: cover;
                                width: 305px;
                                height: 55px;
                                left: 50%;
                                top: 100%;
                                transform: translate(-50%, 10px);
                                pointer-events: none;

                                animation-name: fadeOut;
                                animation-delay: 3s;
                                animation-duration: 0.5s;
                                animation-fill-mode: forwards;
                            }
                        }
                    }
                    .UploadButton{
                        display: flex;
                        align-items: center;
    
                        button{
                            min-width: inherit;
                            padding: 6px;
                        }
                    }
                }
            }
        }
    }

    .DashboardInner{
        margin: 0 auto;
        max-width: 1608px;
        
        @container (width <   792px)                      { width:  315px; }
        @container (width >=  792px) and (width < 1200px) { width:  792px; }
        @container (width >= 1200px) and (width < 1608px) { width: 1200px; }
        @container (width >= 1608px)                      { width:   100%; }
    }
}


.TemplateButton{
    background: rgb(80, 80, 80);
    color: rgb(255,255,255);
    font-weight: bold;
    border-radius: 5px;
    padding: 8px 0;
    font-family: inherit;
    font-size: 1rem;
    min-width: 20px;
    height: 35px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-size: 0.6rem;
    border-left: 1px lightgray solid;

    &:hover{
        background: rgb(80, 80, 80);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}