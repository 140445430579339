
.ShortLinkGeneratorContainer{
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 24px;

    border-radius: 16px;
    border: 2px solid rgba(237, 236, 238, 1);
    box-sizing: border-box;

    @container (width > 768px) {
        width: 100%;
        max-width: 688px;
        padding: 36px 40px;
    }
    @container (width < 768px) {
        width: calc(100% - 20px);
        max-width: 768px;
        padding: 24px 20px;
    }

    .GeneratorTitle{
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 32px; /* 133.333% */
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        @container (width > 768px) {
            margin-bottom: 24px;
        }
        @container (width < 768px) {
            margin-bottom: 16px;
        }
    }

    .GeneratorDetail {
        width: 100%;
        max-height: 100vh;
        transition: 200ms;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        gap: 24px;

        &.Hide {
            max-height: 0;
        }
    }

    .GeneratorSelectBox{
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 16px;

        >div{
            flex: 1;
        }
    }

    .ArrowDown{
        width: 100%;

        display: flex;
        justify-content: center;
    }
    .AddressGenerator{
        width: 100%;

        .GeneratorInputBox{
            width: 100%;
            
            display: flex;
            gap: 16px;
    
            font-family: Pretendard;
            font-style: normal;
    
            font-size: 1rem;
            font-weight: 400;
            line-height: 28px; /* 160% */
            letter-spacing: 0.16px;
            
            @container (width > 768px) {
                flex-direction: row;
                align-items: flex-end;
            }
            @container (width < 768px) {
                flex-direction: column;
            }
    
            .FixedAddress{
                display: flex;
                align-items: center;
                gap: 8px;
    
                .Address{
                    height: 50px;
                    padding: 8px 16px;
                    box-sizing: border-box;
    
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 10px;
    
                    color: rgba(158, 157, 164, 1);
                    border-radius: 5px;
                    border: 1px solid rgba(236, 237, 243, 1);
                    background: linear-gradient(0deg, rgba(220, 220, 220, 0.32) 0%, rgba(220, 220, 220, 0.32) 100%), #FFF;
                
                    @container (width < 768px) {
                        flex: 1;
                    }
                }
            }
        }

        .RandomUrlCheckBox{
            display: flex;
            justify-content: flex-end;

            .RandomCustomUrlCheck{
                padding: 0;
                border: 0;

                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                gap: 8px;

                background-color: rgba(255,255,255,1);
                border-radius: none;
            }
        }
    }

    .CreateShortUrlButton{
        padding: 8px 16px;
        margin-top: 16px;

        display: flex;
        justify-content: center;
        align-items: center;
        
        border-radius: 8px;
        font-family: Pretendard;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
        border-color: rgba(61, 106, 255, 1);
    }
}