.InfoIconContainer{
    position: relative;
    width: 18px;
    height: 18px;
    vertical-align: middle;

    .InfoIconBox{
        cursor: pointer;
    }

    .Description{
        position: absolute;
        height: 61px;
        top: -64px;
        right: 0;
        z-index: 1;

        @media(max-width: 1024px){
            top: -56px
        }
    }
}