
.WebtlyLandingArea{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .WebtlyLandingHeader{
        width: 100%;
        max-width: 1024px;
        padding: 17px 40px;
        box-sizing: border-box;

        display: flex;
        justify-content: space-between;

        .WebtlyLogoBox{
            display: flex;
        }

        .WebtlyLoginBox{
            display: flex;
            align-items: center;

            .UnregisterInfo{
                text-align: center;
                margin-bottom: 25px;
            }
            .LogoutMenu{
                font-weight: 600;
                line-height: 22px;
                cursor: pointer;
            }
        }
        @container (width < 768px) {
            display: flex;
            padding: 15px 24px;
        }
    }

    .WebtlyLandingInner{
        max-width: 1024px;
        width: 100%;
        padding-bottom: 40px;
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;
    
        font-style: normal;
        font-family: inherit;
        
        container-type: inline-size;

        .WebtlyLandingDescription{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .Title{
                padding: 32px 40px;

                display: flex;
                justify-content: inherit;
                
                font-size: 2.5rem;
                font-weight: 700;
                line-height: 60px; /* 150% */

                @container (width < 768px) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;

                    font-size: 2rem;
                    line-height: 48px; /* 150% */
                }

                .SeperatedTexts{
                    text-align: center;
                    word-break: keep-all;
                }
            }
            .SubTexts{
                display: flex;
                flex-direction: column;
                align-items: center;
    
                .SubTitle{
                    color: rgb(60,70,81);
                    font-size: 1.5rem;
                    text-align: center;
                    line-height: 36px; /* 150% */

                    @container (width < 768px) {
                        font-size: 1.25rem;
                        line-height: 30px; /* 150% */
                    }
                }
                .Texts{
                    display: flex;
                    flex-direction: column;
    
                    color: rgba(86, 87, 91, 1);
                    text-align: center;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 24px; /* 150% */

                    @container (width < 768px) {
                        font-size: 0.875rem;
                        line-height: 20px; /* 150% */
                    }
                }
                .PC.Texts{
                    @container (width < 768px) {
                        display: none;
                    }
                }
                .Mobile.Texts{
                    @container (width > 768px) {
                        display: none;
                    }
                }
            }
        }

    }
}