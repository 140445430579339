

.MarketingResultChartArea{
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;

    .LegendBox{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;

        .LegendOutline{
            display: flex;
            flex-direction: column;
            width: 100%;

            .visx-legend-item{
                display: flex;
                align-items: center;
                position: relative;
                margin-bottom: 1px;
    
                .visx-legend-shape{
                    width: 10px; height: 10px;
                    border-radius: 50%;
    
                    .visx-legend-shape-square{
                        width: 10px; height: 10px;
                    }
                }
    
                .visx-legend-label{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 0.75rem;
                    margin-left: 2px;
                    margin-bottom: 2px;
    
                    @media (max-width: 700px) {
                        font-size: 0.625rem;
                    }
                }
            }
        }
    }
}

.ChartDetailDialog{
    .ChartDetailDialogInner{
        padding: 28px;
        box-sizing: border-box;

        @media (max-width: 700px) {
            padding: 14px;
        }

        .ChartDetailTitleBox{
            .Title{
                font-size: 1.5rem;
                font-weight: bold;
                margin-bottom: 8px;
            }
            .SelectedPeriod{
                font-size: 1rem;
                color: #AFAFAF;
            }
        }

        .ChartDetailChartBox{
            height: 500px;

            @media (max-width: 700px) {
                width: 100%;
                height: 250px;
            }
        }
    }
}

.visx-tooltip{
    z-index: 1301;
}