.TextCheckBox{
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;

    box-sizing: border-box;
    height: 48px;
    padding-left: 14px;
    padding-right: 20px;
    border-radius: 5px;
    border: 1px solid var(--light-line-alternative, rgba(236, 237, 243, 1));

    color: var(--light-text-blue-tone-alternative, rgba(121, 120, 130, 1));
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    
    &.Disabled {
        color: rgb(200,200,200);
        
        .CustomCheckBox .CheckMark {
            display: inline-block;
            width: 100%;
            height: 100%;

            border-radius: 0.25rem;
            border: 1px solid rgba(213, 213, 216, 1);
            box-shadow: none !important;

            background: var(--state-disabled-textfield, linear-gradient(0deg, rgba(138, 138, 138, 0.25) 0%, rgba(138, 138, 138, 0.25) 100%), #F6F6F9);
        }
    }

    &.FullWidth{
        display: flex;
        width: 100%;
    }

    .CustomCheckBox{
        width: 18px;
        height: 18px;
        flex-shrink: 0;
        position: relative;

        cursor: pointer;

        .CheckMark{
            display: inline-block;
            width: 100%;
            height: 100%;

            border-radius: 4px;
            border: 1px solid rgba(121, 120, 130, 1);
            box-shadow: 0px 0px 2px 0px rgba(121, 120, 130, 1) inset;

            background-color: rgba(246, 246, 249, 1);
        }

        input:checked + .CheckMark{
            background: rgba(61, 106, 255, 1) url('https://static.webtoon.today/ddah/icon/icon_checkmark.svg') center/1rem no-repeat;
        }

        input {
            display: none;
        }
    }

    &.Checked{
        background-color: rgba(246, 246, 249, 1);
        color: rgba(25, 24, 27, 1);
    }

    &.Small{
        justify-content: flex-end;

        @media (max-width: 700px){
            justify-content: flex-start;
        }
    }
}