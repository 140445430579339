
.LinkCard{
    border-radius: 12px;
    padding: 20px;
    background-color: rgba(255, 255, 255, 1);

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 479px){
        position: relative;
    }

    .LinkCardInfo{
        padding-right: 12px;
        
        display: flex;
        flex-direction: row;
        gap: 24px;
        min-width: 0;

        @media (max-width: 479px){
            width: 100%;
            padding: 0;

            flex-direction: column;
            gap: 12px;
        }

        .LinkCardInfoThumbnail{
            @media (max-width: 479px){
                text-align: center;
            }
        }

        .LinkCardInfoDetail{
            display: flex;
            flex-direction: column;
            gap: 12px;
            min-width: 0;

            .LinkCardInfoDetailTitle{
                color: rgba(60, 70, 81, 1);
                font-size: 1rem;
                font-style: normal;
                font-weight: 500;
                line-height: 24px; /* 150% */

                @media (max-width: 479px){
                    font-size: 0.875rem;
                    line-height: 20px;
                }
                
                a {
                    font: unset;
                    color: unset;
                    padding: unset;
                    margin: unset;
                    text-transform: unset;
                    min-width: 0;
                    display: flex;
                    justify-content: flex-start;
                    
                    span{
                        display: inline-block;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-size: 1.25rem;
                        font-weight: 600;
                        line-height: 30px; /* 150% */
    
                        @media (max-width: 479px){
                            font-size: 1rem;
                            line-height: 24px;
                        }
                    }
                }

            }

            .LinkCardInfoDetailLink{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;
                
                a {
                    font: unset;
                    color: unset;
                    padding: unset;
                    margin: unset;
                    text-transform: unset;
                }

                span{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: rgba(37, 89, 244, 1);
                    font-size: 1.125rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 28px; /* 155.556% */

                    @media (max-width: 479px){
                        font-size: 0.875rem;
                        line-height: 20px;
                    }
                }
            }

            .LinkCardInfoDetailDate{
                display: flex;
                align-items: center;
                gap: 4px;

                color: rgba(158, 157, 164, 1);
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 142.857% */
            }
        }
    }

    .MoreButton{
        @media (max-width: 479px){
            position: absolute;
            top: 20px;
            right: 20px;
        }
    }
}