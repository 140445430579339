

.ShortLinkResultArea{
    margin: 0 auto 40px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 24px;

    border-radius: 16px;
    border: 2px solid rgba(237, 236, 238, 1);
    box-sizing: border-box;
    
    @container (width > 768px) {
        width: 100%;
        max-width: 688px;
        padding: 36px 40px;
    }
    @container (width < 768px) {
        width: calc(100% - 20px);
        max-width: 768px;
        padding: 24px 20px;
    }

    .ShortLinkResultContainer{
        width: 100%;

        .ShowGeneratedLink{
            height: 50px;
            padding: 8px 16px 8px 14px;
            box-sizing: border-box;

            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            position: relative;

            border-radius: 4px;
            border: 1px solid rgba(236, 237, 243, 1);

            .LinkAddress{
                max-width: calc(100% - 73px);
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .CopyButton{
                padding: 8px 10.5px;
                cursor: pointer;

                display: flex;
                align-items: center;
                justify-content: center;
                gap: 4px;

                position: absolute;
                right: 16px;
                top: 50%;
                transform: translateY(-50%);

                background: linear-gradient(0deg, rgba(60, 70, 81, 0.18) 0%, rgba(60, 70, 81, 0.18) 100%), rgba(242, 242, 247, 1);
                border-radius: 5px;
            }
        }
    }

    .ResultTitle{
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 32px; /* 133.333% */

        @container (width > 768px) {
            margin-bottom: 24px;
        }
        @container (width < 768px) {
            margin-bottom: 16px;
        }
    }

    .WebtlyResultButtonBox{
        width: 100%;
        padding-top: 16px;

        display: flex;
        justify-content: space-between;
        
        @container (width < 768px) {
            display: flex;
            flex-direction: column;
            gap: 12px;
            padding: 0;
        }
        Button{
            padding: 8px 10.5px;

            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
        }
    }
}