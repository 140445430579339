.SignInHome{
    min-height: calc(100vh - 72px);
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(245, 246, 247, 1);

    .SignInHomeContent{
        box-sizing: border-box;
        padding: 32px 16px;
        width: 480px;
        border: 1px solid rgba(237, 236, 238, 1);
        border-radius: 8px;
    
        display: flex;
        flex-direction: column;
        gap: 32px;

        background-color: rgba(255, 255, 255, 1);

        .SignInHomeContentHeader{
            padding: 0px 14px 16px 14px;

            display: flex;
            flex-direction: column;
            gap: 4px;

            .Title{
                color: rgba(25, 24, 27, 1);
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 700;
                line-height: 36px; /* 150% */
                letter-spacing: 0.24px;
            }

            .Subtitle{
                color: rgba(71, 71, 71, 1);
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 150% */
                letter-spacing: 0.16px;
            }
        }
    }
}