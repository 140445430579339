.SignIn{
    .SignInHeader{
        height: 72px;

        display: flex;
        justify-content: center;
        align-items: center;

        a{
            height: 38px;
        }
    }
}