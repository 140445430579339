.SNSLoginButtonsArea{
    box-sizing: border-box;
    padding: 0px 14px;
    max-width: 448px;
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 16px;

    .SNSButton > * {
        width: 100%;
        height: 48px;
        border-radius: 8px;
    }
}