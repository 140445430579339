.OneColumnFullpageDialog {
    position: fixed;
    top: 0; left: 0;
    display: flex;
    width: 100%;
    height: 100vh;

    justify-content: center;
    align-items: center;

    .CenterDialog {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        gap: 24px;

        .OCFDTitle {
            position: relative;
        }

        .OCFDContents {
            position: relative;
        }

        .OCFDFooter {
            position: relative;
        }
    }
}