@import './LinkCard.scss';

.Link{
    background-color: rgba(245, 246, 248, 1);

    .LinkHeader{
        box-sizing: border-box;
        padding: 36px 40px 16px 40px;
        height: 96px;

        display: flex;
        flex-direction: row;
        justify-content: space-between;

        color: rgba(25, 24, 27, 1);
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 600;
        line-height: 44px; /* 157.143% */
    }

    .LinkContentEmpty{
        min-height: calc(100vh - 96px);
        
        display: flex;
        justify-content: center;
        align-items: center;

        > div{
            display: flex;
            flex-direction: column;
            gap: 20px;

            text-align: center;

            > div:first-child{
                display: flex;
                flex-direction: column;
                gap: 12px;

                word-break: keep-all;
                h1{
                    margin: 0;
                    color: rgba(0, 0, 0, 1);
                    font-size: 2rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 48px; /* 150% */
                    letter-spacing: 0.32px;
                }
        
                h4{
                    margin: 0;
                    color: rgba(121, 120, 130, 1);
                    font-size: 1.25rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 30px; /* 150% */
                    letter-spacing: 0.2px;
                    white-space: pre-line;
                }
            }
        }
    }

    .LinkContent{
        min-height: calc(100vh - 96px);

        padding: 0px 40px;

        display: flex;
        flex-direction: column;
        gap: 20px;

        @media (max-width: 767px){
            padding: 0px 20px;
        }

        @media (max-width: 479px){
            padding-bottom: 40px;
        }

    }
}

.LinkCardMenu{
    .LinkCardMenuList{
        box-sizing: border-box;
        min-width: 140px;
        padding: 10px 12px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .LinkCardMenuItem{
            padding: 6px 8px;
            display: flex;
            align-items: center;
            gap: 6px;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;

            cursor: pointer;

            &:hover{
                border-radius: 5px;
                background: linear-gradient(0deg, rgba(60, 70, 81, 0.12) 0%, rgba(60, 70, 81, 0.12) 100%), #FFF;
            }
        }
    }
}